import { useState } from 'react';
import { put } from '@vercel/blob';

import './App.css';

function App() {


	const [selectedImage, setSelectedImage] = useState(null);

	const handleImageChange = (event) => {
		const file = event.target.files[0];
		setSelectedImage(file);
	};

	const uploadImage = async () => {
		if(selectedImage !== null) {
			const file = selectedImage;
			const blob = await put(file.name, file, { access: 'public' });
			console.log(blob)
		}
		else {
			console.log("Kindly Select an Image")
		}
	}

	return (
		<div className="App">

			<h1>Upload Your Avatar</h1>

			<form onSubmit={uploadImage} >
				<input name="file" onChange={handleImageChange} type="file" accept="image/*" required />
				<button type="submit">Upload</button>
			</form>
			
			{selectedImage && (
				<div>
					<p>Selected Image:</p>
					<img
						src={URL.createObjectURL(selectedImage)}
						alt="Selected"
						width="200"
					/>
				</div>
			)}
		</div>
	);
}

export default App;
